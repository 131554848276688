<template>
  <b-card-code title="">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->

    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: this.deletableTable,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        @on-selected-rows-change="selectionChanged"

    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: File -->
        <span v-if="props.column.label === 'File'">
          <div style="display: flex; justify-content: center; cursor: pointer;">
            <a
                :href="props.formattedRow[props.column.field]"
                target="_blank"
            >
              <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="text-body align-middle mr-25"
              />
            </a>
          </div>
        </span>

        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'Name'"
            class="text-nowrap"
        >
          <b-avatar
              :src="props.row.avatar"
              class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Status -->
        <span
            v-else-if="props.column.field === 'ready' || props.column.field === 'is_active' || props.column.field === 'is_approved' || props.column.field === 'is_redeemed' || props.column.field === 'is_released_report'">
          <div style="display: flex; justify-content: center">
            <b-form-checkbox
                v-if="props.column.field === 'ready' || props.column.field === 'is_active'"
                :checked="props.row.is_active === 0 ? false : true"
                class="custom-control-secondary"
                name="check-button"
                switch
                @change="changeStatus(props.row)"
            />
            <b-form-checkbox
                v-if="props.column.field === 'is_approved'"
                :checked="props.row.is_approved === 0 ? false : true"
                class="custom-control-secondary"
                name="check-button"
                switch
                @change="changeStatus(props.row)"
            />
            <b-form-checkbox
                v-if="props.column.field === 'is_redeemed'"
                :checked="props.row.is_redeemed === 0 ? false : true"
                class="custom-control-secondary"
                name="check-button"
                switch
                @change="changeStatus(props.row)"
            />
            <b-form-checkbox
                v-if="props.column.field === 'is_released_report'"
                :checked="props.row.is_released_report === 0 ? false : true"
                class="custom-control-secondary"
                name="check-button"
                switch
                @change="changeStatus(props.row)"
            />
            <b-badge v-if="props.column.field === 'ready'">
              {{ props.row.is_active == 0 ? 'Not Published' : ' &nbsp; Published  &nbsp; &nbsp;  ' }}
            </b-badge>
            <b-badge v-if="props.column.field === 'is_approved'">
              {{ props.row.is_approved == 0 ? 'Under Review' : ' &nbsp; Reviewed  &nbsp; &nbsp;  ' }}
            </b-badge>
            <b-badge v-if="props.column.field === 'is_redeemed'">
              {{ props.row.is_redeemed == 0 ? 'Not Redeemed' : ' &nbsp; Redeemed  &nbsp; &nbsp;  ' }}
            </b-badge>
            <b-badge v-if="props.column.field === 'is_released_report'">
              {{ props.row.is_released_report === 0 ? 'Not Released' : ' Released  &nbsp; &nbsp;  ' }}
            </b-badge>
            <b-badge v-if="props.column.field === 'is_active'">
              {{ props.row.is_active === 0 ? 'Not Published' : ' &nbsp; Published  &nbsp; &nbsp;  ' }}
            </b-badge>
          </div>

        </span>
        <span v-else-if="props.column.field === 'is_featured'">
          <div style="display: flex; justify-content: center">
            <b-form-checkbox
                :checked="props.row.is_featured === 0 ? false : true"
                class="custom-control-secondary"
                name="check-button"
                switch
                @change="changeFeturedStatus(props.row)"
            />
            <b-badge>
              {{ props.row.is_featured == 0 ? 'No' : ' &nbsp; Yes ' }}
            </b-badge>
          </div>

        </span>
        <span v-else-if="props.column.field === 'statusSort'">
          <div style="display: flex; justify-content: center">
            <b-form-checkbox
                :checked="props.row.statusSort === 'B' ? false : true"
                class="custom-control-secondary"
                name="check-button"
                switch
                @change="changeStatus(props.row)"
            />
            <b-badge>
              {{ props.row.statusSort == 'B' ? 'Not Published' : ' &nbsp; Published ' }}
            </b-badge>
          </div>

        </span>

        <span v-else-if="props.column.label === 'Change Status'"/>

        <!-- Column: Multi Status is_featured-->
        <span v-else-if="props.column.label === 'Pay'">
          <div style="display: flex; justify-content: center">
            <button
                v-if=" props.formattedRow[props.column.field] > 0"
                type="button"
                class="btn btn-outline-danger"
                @click="pay(props.row)"
            > Pay {{ new Intl.NumberFormat().format(props.formattedRow[props.column.field]) }} AED</button>
            <button
                v-else
                type="button"
                class="btn btn-outline-dark"
                style="cursor: not-allowed"
                disabled
            >Pay {{ props.formattedRow[props.column.field] }} AED</button>
          </div>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                  v-if="editableTable && ( (props.row.canDelete && props.row.canDelete === true) || props.row.canDelete === undefined)"
                  data-toggle="modal"
                  data-target="#editModal"
                  data-backdrop="static"
                  data-keyboard="false"
                  @click="getElement(props.row)"
              >
                <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="deletableTable && ( (props.row.canDelete && props.row.canDelete === true) || props.row.canDelete === undefined)"
                  @click="catchId(props.row.id)"
              >
                <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                />
                <span v-if="$router.currentRoute.name !== 'sub_organizations_orders'">Delete</span>
                <span v-else>Cancel</span>

              </b-dropdown-item>


               <b-dropdown-item v-if="props.row.canDelete !== undefined && props.row.canDelete === false && $router.currentRoute.name !== 'user-invoices'"><span style="cursor: not-allowed"> Can not Edit/Delete </span></b-dropdown-item>
              <b-dropdown-item
                  v-for="(action, index) of actionsObj.filter(el => {
                  return el.conditionalColumn && props.row[el.conditionalColumn] || !el.conditionalColumn;
                })"
                  :key="index"
                  :data-toggle="action.modal ? 'modal': false"
                  :data-target="action.modal ? action.modal:false "
                  data-backdrop="static"
                  data-keyboard="false"
                  @click="clickAction(action.title, props.row.id, props.row)"
              >
                <feather-icon
                    :icon="action.icon"
                    class="mr-50"
                />

                <span>{{ action.title }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- Column: Cover -->
        <span v-else-if="props.column.field === 'cover'">
          <img
              height="100"
              width="100"
              :src="props.formattedRow[props.column.field].length > 0 ? props.formattedRow[props.column.field][0]['url'] : ''"
          >
        </span>
        <!-- Column: Icon -->
        <span v-else-if="props.column.field === 'icon'">
          <img
              height="100"
              width="100"
              :src="props.formattedRow[props.column.field].length > 0 ? props.formattedRow[props.column.field][0]['url'] : ''"
          >
        </span>
        <!-- Column: Image -->
        <span v-else-if="props.column.field === 'image'">
          <img
              height="100"
              width="100"
              :src="props.formattedRow[props.column.field].length > 0 ? props.formattedRow[props.column.field][0]['url'] : ''"
          >
        </span>
        <!-- Column: Common -->
        <span v-else>
          <span
              v-if="props.column.label !== 'File' && props.column.label !== 'Message'"
          >{{ props.formattedRow[props.column.field] }}</span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import {codeBasic} from './code'
import {codeColor} from './switchColorCode/code'

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: ['columns', 'values', 'actionsObj', 'selectedPage', 'editablePage', 'deletablePage'],
  data() {
    return {
      editableTable: true,
      deletableTable: true,
      nextStatus: 'Completed',
      codeColor,
      deletedRows: [],
      showMultipleButton: false,
      pageLength: 10,
      edit_object: '',
      dir: false,
      codeBasic,
      rows: [],
      titles: [],
      searchTerm: '',
      statusValue: [],
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Pending: 'dark-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    values(newV, oldV) {
      this.rows = newV
    },
  },
  created() {
    this.rows = this.values
    this.editableTable = this.editablePage ?? true
    this.deletableTable = this.deletablePage ?? true
    // this.$http.get('/good-table/basic')
    //   .then(res => { this.rows = res.data })
  },
  mounted() {
    if (document.getElementById('deleteMultiple') !== null) {
      document.getElementById('deleteMultiple').disabled = true
      document.getElementById('deleteMultiple').style.cursor = 'not-allowed'
    }

    //
    //
    //
  },
  methods: {
    selectionChanged(params) {
      const arr = params.selectedRows.map(el => el.id)
      if (arr.length === 0) {
        document.getElementById('deleteMultiple').disabled = true
        document.getElementById('deleteMultiple').style.cursor = 'not-allowed'
        // if (document.getElementById('paid-amount') != null) document.getElementById('paid-amount').innerText = '0 AED'
      } else {
        document.getElementById('deleteMultiple').disabled = false
        document.getElementById('deleteMultiple').style.cursor = 'pointer'
        this.$emit('deletedMultipleIds', arr)
      }
      this.$emit('catchSelectedElements', params.selectedRows)
    },
    getElement(obj) {
      this.edit_object = obj
      this.$emit('choosenObject', this.edit_object)
    },
    clickAction(key, id, row) {
      this.$emit('dynamicAction', {
        key,
        id,
        row,
      })
    },
    catchId(id) {
      const arr = []
      arr.push(id)
      this.$emit('deleteItems', arr)
    },
    catchElements(elements) {
      this.$emit('catchSelectedElements', elements)
    },
    confirmText() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
    },
    changeStatus(row) {
      if (row.is_active !== undefined) {
        if (row.is_active === 'A') {
          const item = {
            item: row,
            value: 0,
          }
          this.$emit('changeStatus', item)

        } else if (row.is_active === 'B') {
          const item = {
            item: row,
            value: 1,
          }
          this.$emit('changeStatus', item)

        } else {
          const item = {
            item: row,
            value: !row.is_active,
          }
          this.$emit('changeStatus', item)

        }

      }
      if (row.is_approved !== undefined) {
        const item = {
          item: row,
          value: !row.is_approved,
        }
        this.$emit('changeStatus', item)
      }
      if (row.is_redeemed !== undefined) {
        const item = {
          item: row,
          value: !row.is_redeemed,
        }
        this.$emit('changeStatus', item)
      }
    },
    changeFeturedStatus(row) {
      const item = {
        item: row,
        value: !row.is_featured,
      }
      this.$emit('changeIsFeatureStatus', item)
    },
    getAction(row) {
      this.$emit('getAction', row)
    },
    getStatus(row) {
      this.$emit('catchStatus', row)
    },
    pay(row) {
      this.$emit('catchPayAction', row)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.s-hover:hover {
  color: #a5af26;
  cursor: pointer;
}

.vgt-responsive {
  width: 100%;
  overflow-x: inherit !important;
  position: relative;
}
</style>
