<template>
  <div  style="height: 71vh">
    <div
        class="spinner-grow text-primary"
        style="margin-top: 15%;margin-left: 45%;"
    />
  </div>
</template>
<script>
export default {
  name: "Spinner"
}
</script>

<style scoped>

</style>