import AbstractService from '@/services/abstractService'

class AdminService extends AbstractService {
  endpoint = 'roles'

  adminEndpoint = 'admins'

  // Admin CRUD
  admins() {
    return this.http.get(`${this.adminEndpoint}`, { params: { dashboard: true } })
  }

  users(role) {
    return this.http.get(`${this.adminEndpoint}`, { params: { dashboard: true, role } })
  }

  postAdmin(data) {
    const formData = new FormData()
    for (const key in data) {
      if (key === 'roles') {
        formData.append('roles[0]', data[key])
      } else formData.append(key, data[key])
    }
    return this.http.post(this.adminEndpoint, formData)
  }

  putAdmin(id, data) {
    const formData = new FormData()
    for (const key in data) {
      if (key === 'roles') {
        formData.append('roles[0]', data[key])
      } else formData.append(key, data[key])
    }
    return this.http.put(`${this.adminEndpoint}/${id}`, formData)
  }

  deleteAdmin(data) {
    return this.http.delete(`${this.adminEndpoint}/${data}`)
  }

  // Roles CRUD
  index() {
    return this.http.get(`${this.endpoint}`)
  }

  adminRoles() {
    return this.http.get(`${this.endpoint}`, { params: { only_admins: true } })
  }

  delete(data) {
    return this.http.delete(`${this.endpoint}/${data}`)
  }

  post(data) {
    return this.http.post(this.endpoint, data)
  }

  put(id, data) {
    return this.http.put(`${this.endpoint}/${id}`, data)
  }

  // Permissions CRUD

  permissions() {
    return this.http.get('permissions', { params: { dashboard: true } })
  }

  permissionsByRole(roleId) {
    return this.http.get(`${this.endpoint}/${roleId}`, { params: { dashboard: true } })
  }

  AssignPermissions(roleId, data) {
    const formData = new FormData()
    for (const key in data) {
      formData.append(`permissions[${key}]`, data[key])
    }
    return this.http.post(`${this.endpoint}/${roleId}/permissions`, formData)
  }

  // Roles CRUD

  rolesByAdmin(adminId, role) {
    return this.http.get(`${this.adminEndpoint}/${adminId}/${this.endpoint}`, { params: { dashboard: true, role } })
  }

  AssignRoles(adminId, data) {
    const formData = new FormData()
    for (const key in data) {
      formData.append(`roles[${key}]`, data[key])
    }
    return this.http.post(`${this.adminEndpoint}/${adminId}/${this.endpoint}`, formData)
  }

  changePassword(data) {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.post(`${this.adminEndpoint}/${data.id}/changePassword`, formData)
  }
}

const Service = new AdminService()

export default Service
