<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <!-- The Modal -->
  <div
      id="resetPasswordModal"
      class="modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              title="Reset Admin Password"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          name="Password"
                          vid="Password"
                          rules="required|min:6"
                      >
                        <b-form-input
                            id="clientPassword"
                            v-model="newPassword.password"
                            type="password"
                            :state="errors.length > 0 ? false:null"
                            placeholder="New Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          name="Password Confirm"
                          rules="required|confirmed:Password"
                      >
                        <b-form-input
                            id="clientConfirmPassword"
                            v-model="newPassword.password_confirmation"
                            :state="errors.length > 0 ? false:null"
                            type="password"
                            placeholder="Confirm Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea,

} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import { codeMultiple, codeBasic } from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import adminService from '@/services/adminService'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  props: ['user'],
  data() {
    return {
      newPassword: {
        id: '',
        password: '',
        password_confirmation: '',
      },
      titles: ['Mr.', 'Ms.'],
      PasswordValue: '',
      passwordCon: '',
      required,
      password,
      email,
      confirmed,
      codeMultiple,
      codeBasic,
      selected: '',
    }
  },
  mounted() {

  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.newPassword.id = this.user
              adminService.changePassword(this.newPassword)
                  .then(response => {
                    $('#resetPasswordModal') .modal('hide')
                    if(response && response.status === 200){
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: `Password Is Changed Successfully !`,
                        },
                      })
                      this.newPassword= {
                        id: '',
                        password: '',
                        password_confirmation: ''
                      },
                          this.$emit('refresh', 'true')
                    }

                    this.loading = false
                  })
            }})
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}
</style>
