<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <!-- The Modal -->
  <div
      id="createModal"
      class="modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              title="Create Client"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="fullName">Name</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Full Name"
                          rules="required"
                      >
                        <b-form-input
                            id="fullName"
                            v-model="createdAdmin.name"
                            name="fullName"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="mobile">Mobile</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Mobile"
                          rules="required"
                      >
                        <b-form-input
                            id="mobile"
                            v-model="createdAdmin.mobile"
                            name="mobile"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="email">Email</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required|email"
                      >
                        <b-form-input
                            id="email"
                            v-model="createdAdmin.email"
                            type="email"
                            name="email"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="Password">Password</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Password"
                          rules="required"
                      >
                        <b-form-input
                            id="password"
                            v-model="createdAdmin.password"
                            type="password"
                            name="password"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
<!--                  <b-col md="12">-->
<!--                    <b-form-group>-->
<!--                      <label for="first_name">Roles</label>-->
<!--                      <validation-provider-->
<!--                          #default="{ errors }"-->
<!--                          name="roles"-->
<!--                          rules="required"-->
<!--                      >-->
<!--                        <v-select-->
<!--                            name="roles"-->
<!--                            v-model="createdAdmin.roles"-->
<!--                            class="parent-service"-->
<!--                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                            :options="roles"-->
<!--                            :reduce="name => name.id"-->
<!--                            label="name"-->
<!--                        />-->
<!--                        <small class="text-danger">{{ errors[0] }}</small>-->
<!--                      </validation-provider>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
                </b-row>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea,

} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import { codeMultiple, codeBasic } from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import adminService from '../../../services/adminService'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      roles: [],
      adminId: '',
      assignedRoles: { roles: [] },
      createdAdmin: {
        name: '',
        password: '',
        mobile: '',
        email: '',
        roles: ['2'],
      },
      required,
      password,
      email,
      confirmed,
      codeMultiple,
      codeBasic,
    }
  },
  mounted() {
    this.getRoles()
    this.$watch(() => this.admin, admin => {
      this.adminId = admin
      this.getRolesByAdmin()

    })

  },
  methods: {
    getRoles() {
      this.loading = true
      adminService.adminRoles()
          .then(response => {
            this.roles = response.data.data
          })
    },
    getRolesByAdmin() {
      adminService.rolesByAdmin(this.adminId)
          .then(response => {
            this.assignedRoles.roles = response.data && response.data.data.length >= 0 ? response.data.data : []
          })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              adminService.postAdmin({
                ...this.createdAdmin,
                roles: this.createdAdmin.roles
              })
                  .then(response => {
                    if (response && response.data.code === 200) {
                      this.$emit('refresh', 'true')
                      $('#createModal').modal('hide')
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: 'Admin Is Created Successfully !',
                        },
                      })
                    } else{
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Error',
                          icon: 'CheckIcon',
                          variant: 'danger',
                          text: response.data.message,
                        },
                      })
                      $('#createModal').modal('hide')

                    }
                    this.loading = false
                  })
            }
          })
          .catch(function (error) {
            $('#createModal')
                .modal('hide')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'Error',
                text: 'Error !',
              },
            })
            return Promise.reject(error)
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

#createModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
