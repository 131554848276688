<template>
  <div>

    <Spinner v-if="loading === true" />

    <div
      v-if="loading === false"
      class="row"
    >
      <div class="col-md-6" />
      <div class="col-md-6" />
    </div>
    <basic-table
      v-if="loading === false"
      ref="table"
      :columns="columns"
      :values="rows"
      :actions-obj="actionsArray"
      :editable-page="false"
      :deletable-page="false"
      @dynamicAction="handleActions($event)"
      @choosenObject="getEditedObject($event)"
      @deleteItems="removeItems($event)"
      @deletedMultipleIds="catchIds($event)"
    />
    <edit
      v-if="$router.currentRoute.name === 'clients'"
      :admin="editedAdmin"
      @refresh="getScents()"
    />
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import BasicTable from '../../../shared/tables/BasicTable.vue'
import Create from './create.vue'
import Edit from './edit.vue'
import invoiceService from '../../../services/invoiceService'
import Roles from './roles.vue'
import ResetPassword from './resetPassword.vue'
import Spinner from '@/shared/loader/Spinner'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Create,
    Edit,
    ResetPassword,
    Roles,
    BasicTable,
  },
  data() {
    return {
      loading: false,
      userId: '',
      actionsArray: [],
      editedAdmin: '',
      roleId: '',
      columns: [
        {
          label: 'Sku Id',
          field: 'skuId',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Device',
          field: 'device.title',
        },
        {
          label: 'Invoice',
          field: 'invoice.invoice_number',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getScents()
    // this.actionsArray = [{ title: 'Devices', icon: 'PlayIcon' }]
  },
  methods: {
    getScents() {
      this.loading = true
      invoiceService.show(this.$route.params.id).then(response => {
        this.rows = response.data.data.scents
        this.loading = false
      })
    },
    handleActions(itemObj) {
      console.log(itemObj)
      switch (itemObj.key) {
        case 'Devices': {
          this.$router.push({ name: 'invoice-devices', params: { id: itemObj.id} })
        }
          break
        default:

      }
      // this.$router.push({ name: 'representatives', params: { id: itemId } })
    },
    getEditedObject(obj) {
      this.editedAdmin = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.loading = true
      this.confirmDeleteText(arr)
    },
    confirmDeleteText(arr) {
      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            invoiceService.deleteAdmin(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => {
              this.loading = false
              return Promise.reject(error)
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    renderTable() {
      this.getScents()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

