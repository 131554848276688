import AbstractService from '@/services/abstractService'

class InvoiceService extends AbstractService {
  endpoint = 'user-invoices'

  /**
   * get all resource data
   */
  index(id) {
    return this.http.get(`${this.endpoint}/${id}`)
  }

  show(id, params) {
    return this.http.get(`invoice-devices/${id}`, { params })
  }

  showRoomsDevices(id, params) {
    return this.http.get(`room-devices/${id}`, { params })
  }
  showUserRooms(id, params) {
    return this.http.get(`user-rooms/${id}`, { params })
  }

  deleteUserRoom(data) {
    return this.http.delete(`user-rooms/${data}`)
  }
}

const Service = new InvoiceService()

export default Service
